import * as React from 'react';
import { useEffect, useState } from "react";
import "./home.css";
import axios from 'axios';
import Loader from './Loader';

export default function Home() {

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // turn on loader 
    setLoading(true);
    axios.get("https://mdc-tickets.jassy.in/cpfamily/").then((response) => {
      console.log(response.data.event)
      setData(response.data.event);
      setCount(response.data.count);
      // turn off loader
      setLoading(false);
    });
  }, []);


  return (
    <body className='body'>
      {/* if loader is true show loader else the content  */}
      
    <div className="modal">
      <div className="header">
        <span  className="title"> {count} events today..! </span>
      </div>
      {/* display today date here */}
      <div className="date-div">
        <span className="date-text">{new Date().toDateString()}</span>
      </div>
      {/* wish from college here if count not eqal zero*/}
      {/* {count !== 0 ? 
      <div className="wish-div">
        <span className="wish-text">Malabar dental college and DHEEKSHA (mdsu_22-23) wishes you many many happy returns of the day..!</span>
      </div>
      : <div className="wish-div">
      <span className="wish-text">During this date, no students have birthdays</span>
    </div>} */}
      {loading ? <div className="card loader">   <Loader />  </div>:
      <div className="content">
        {data.map((item) => (
          <div className="card">  
            <div className="row">
              <div className='col-4 minheight'>
                {/* if img loaded full show img else show loading  */}
                {item.profile_pic ? <img src={"https://mdc-tickets.jassy.in" + item.profile_pic} alt="profile pic" className="profile-pic" /> : <Loader />}
              </div><br/><br/><br/><br/><br/>
              <div className='col-8'>
              <h5 className="card-title">{item.member_name} </h5> 
              <p className="card-text">Date: {item.event_date}</p>
              <p className="card-text">Event: {item.typeOfEvent}</p>
              </div>
            </div>
          </div>
        ))}
        {/* href button */}
        <div className="btn-center">
        <button className='ok-button'><a href="https://mdc-tickets.jassy.in/cpfamily/addEvent" className="btn btn-primary">Add new event </a> </button>
        </div>
        <p className='text-center-p'>Cp family Event's <br></br></p>
        
      </div>
      }
    </div>

    </body>
  );
}