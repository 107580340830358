import Home from './components/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegisterBday from './components/RegisterEvent';

function App() {
  return (
    <div >
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
